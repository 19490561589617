<template>
  <v-main>
    <v-container fluid fill-height>
      <v-layout align-center justify-center> </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import { projectAuth } from '@/firebase/config'
import { mapGetters } from 'vuex'

export default {
  name: 'HomePage',

  components: {},

  data() {
    return {}
  },

  methods: {
    async logout() {
      projectAuth.signOut().then(() => {
        this.$router.push('/logout')
      })
    },
  },
  computed: {
    ...mapGetters([
      'getUpdateYourPackageStatus',
      'getUpdateYourPackageType',
      'getExplanationNPS_Status',
      'getExplanationNPSType',
    ]),
  },
}
</script>
